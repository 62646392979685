<template>
  <div id="addGift">
    <el-dialog
      :title="isedit ? '编辑礼物' : '添加礼物'"
      class="dialogVisible"
      width="850px"
      :visible="dialogstatus"
      @update:visible="v => $emit('update:dialogstatus', v)"
      v-if="dialogstatus"
      :close-on-click-modal="true"
      :destroy-on-close="true"
      @close="cancel"
    >
      <div class="dialogVisible_bottom">
        <el-form
          ref="form"
          label-position="left"
          :model="form"
          label-width="100px"
        >
          <el-form-item label="礼物名称">
            <el-input
              style="width: 522px"
              v-model="form.name"
              placeholder="请输入礼物名称"
              maxlength="5"
              show-word-limit
            ></el-input>
          </el-form-item>

          <el-form-item label="礼物图片">
            <div class="gift_picture">
              <div class="tu">
                <img v-if="form.icon" :src="form.icon" alt="" />
              </div>
              <div class="right">
                <el-button
                  style="width: 132px"
                  @click="$emit('selctPicture')"
                  class="mb10"
                >
                  选择图片
                </el-button>
                <div class="tip">
                  提示：礼物图片建议尺寸200*200px，或者长宽比为1:1，支持png、jpg格式，且大小不超过2M
                </div>
              </div>
            </div>
          </el-form-item>

          <el-form-item label="礼物价格">
            <div @mousemove="moseover(1)" @mouseleave="moseover(undefined)">
              <el-input-number
                :precision="2"
                v-model="form.price"
                controls-position="right"
                :controls="isOver == 1 ? true : false"
                :step="1"
                :max="9999"
                max-length="4"
              ></el-input-number>
              <span class="ml10">元</span>
            </div>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancel" size="medium">取 消</el-button>
        <el-button type="primary" @click="submit" size="medium">保存</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'addGift',
  data() {
    return {
      isOver: undefined,
      form: {
        name: '',
        icon: '',
        price: 1,
      },
    }
  },
  watch: {
    pictrue() {
      this.form.icon = this.pictrue.url
    },
  },

  mounted() {
    if (this.isedit) {
      const val = _.assign({}, this.editform)
      this.$nextTick(() => {
        this.form = val
      })
    }
  },

  props: {
    isedit: {
      type: Boolean,
      default: false,
    },
    editform: {
      type: Object,
      default: () => {
        return {
          name: '',
          icon: '',
          price: 1,
        }
      },
    },
    pictrue: {
      type: Object,
      default: () => {},
    },
    dialogstatus: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    submit() {
      // this.form = _.assign({}, this.form, {
      //   icon: this.pictrue.url,
      //   name: this.form.name.trim(),
      // })
      if (!this.form.name) {
        this.$root.prompt('请输入礼物名称')
        return
      } else if (!this.form.icon) {
        this.$root.prompt('请添加礼物图片')
        return
      } else if (this.form.price <= 0 || this.form.price > 9999) {
        this.$root.prompt('请输入0.01～9999之间的数字！')
        return
      } else if (!this.form.price) {
        this.$root.prompt('金额不可为0和留空！')
        return
      }
      if (this.isedit) {
        this.$http({
          url: '/giftOrder/editGift',
          data: this.form,
          callback: () => {
            this.$root.prompt({
              msg: '操作成功',
              type: 'success',
            })
            this.cancel()
          },
        })
      } else {
        this.$http({
          url: '/giftOrder/addGift',
          data: this.form,
          callback: () => {
            this.$root.prompt({
              msg: '操作成功',
              type: 'success',
            })
            this.cancel()
          },
        })
      }
    },
    // 展示增加按钮的交互
    moseover(val) {
      this.isOver = val
    },
    cancel() {
      this.form = {
        name: '',
        price: '',
        icon: '',
      }

      this.$emit('colse')

      // 取消后回复选择数据原样
      this.$emit('update:dialogstatus', false)
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .el-input-number.is-controls-right .el-input__inner {
  padding: 0;
}
::v-deep .el-input-number__increase {
  background-color: #fff;
  border: 0 !important;
}
::v-deep .el-input-number__decrease {
  background-color: #fff;
  border-left: 0 !important;
}
::v-deep .el-input-number {
  width: 131px;
}

#addGift {
  .gift_picture {
    display: flex;
    align-items: center;
    .tu {
      border: 1px solid #d0d0d0;
      width: 60px;
      height: 60px;
      margin-right: 20px;
      img {
        height: 100%;
        width: 100%;
        object-fit: contain;
      }
    }
    .right {
      .tip {
        font-size: 12px;
        color: #666666;
        line-height: 12px;
      }
    }
  }
}
</style>
