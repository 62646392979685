<!--
 * @Author: cyy
 * @Date: 2021-12-17 17:45:23
 * @LastEditTime: 2022-10-24 10:39:29
 * @LastEditors: cyy
 * @Description: 
 * @FilePath: \teacherdingding.com\src\views\LiveBroadcastManagement\currencySetting\main.vue
-->
<template>
  <div id="currencySetting">
    <el-tabs v-model="activeName">
      <el-tab-pane label="打赏设置" name="1">
        <reward-setting v-if="activeName == 1"></reward-setting>
      </el-tab-pane>
      <!-- <el-tab-pane label="机器人" name="2">
        <robot-setting v-if="activeName == 2"></robot-setting>
      </el-tab-pane> -->
      <!-- <el-tab-pane label="消息模板" name="3">
        <messageTemplate v-if="activeName == 3"></messageTemplate>
      </el-tab-pane> -->
    </el-tabs>
  </div>
</template>

<script>
import rewardSetting from './rewardSetting'
// import robotSetting from './robotSetting'
// import messageTemplate from './messageTemplate'
import bg from '@/mixin/background'
export default {
  name: 'currencySetting',
  mixins: [bg],
  components: {
    rewardSetting,
    // robotSetting,
    // messageTemplate,
  },

  created() {
    if (this.$route.query.index) {
      this.activeName = this.$route.query.index
    }
  },

  data() {
    return {
      activeName: '1',
    }
  },
}
</script>

<style lang="scss" scoped>
::v-deep .el-tab-pane {
  padding: 20px;
}
::v-deep .el-tabs__nav-wrap {
  padding: 0 30px;
}
::v-deep .el-tabs__nav-wrap::after {
  height: 1px;
}
#currencySetting {
  margin: 20px;
  background-color: #fff;
}
</style>
