<template>
  <div id="moneySet" v-if="complete">
    <div class="menberlist">
      <div @mousemove="moseover(1)" @mouseleave="moseover(undefined)">
        <el-input-number
          :precision="2"
          v-model="post.reward_price_one"
          controls-position="right"
          :controls="isOver == 1 ? true : false"
          :step="1"
          :max="9999"
          max-length="4"
        ></el-input-number>
        <span class="money">元</span>
      </div>

      <div
        class="ml50"
        @mousemove="moseover(2)"
        @mouseleave="moseover(undefined)"
      >
        <el-input-number
          :precision="2"
          v-model="post.reward_price_two"
          controls-position="right"
          :step="1"
          :controls="isOver == 2 ? true : false"
          :max="9999"
        ></el-input-number>
        <span class="money">元</span>
      </div>

      <div
        class="ml50"
        @mousemove="moseover(3)"
        @mouseleave="moseover(undefined)"
      >
        <el-input-number
          :precision="2"
          :controls="isOver == 3 ? true : false"
          v-model="post.reward_price_three"
          controls-position="right"
          :step="1"
          :max="9999"
        ></el-input-number>
        <span class="money">元</span>
      </div>
    </div>

    <div class="menberlist mt30">
      <div @mousemove="moseover(4)" @mouseleave="moseover(undefined)">
        <el-input-number
          :precision="2"
          v-model="post.reward_price_four"
          controls-position="right"
          :controls="isOver == 4 ? true : false"
          :step="1"
          :max="9999"
          max-length="4"
        ></el-input-number>
        <span class="money">元</span>
      </div>

      <div
        class="ml50"
        @mousemove="moseover(5)"
        @mouseleave="moseover(undefined)"
      >
        <el-input-number
          :precision="2"
          v-model="post.reward_price_five"
          controls-position="right"
          :step="1"
          :controls="isOver == 5 ? true : false"
          :max="9999"
        ></el-input-number>
        <span class="money">元</span>
      </div>

      <div
        class="ml50"
        @mousemove="moseover(6)"
        @mouseleave="moseover(undefined)"
      >
        <el-input-number
          :precision="2"
          :controls="isOver == 6 ? true : false"
          v-model="post.reward_price_six"
          controls-position="right"
          :step="1"
          :max="9999"
        ></el-input-number>
        <span class="money">元</span>
      </div>
    </div>

    <el-button
      @click="setRewardSetting"
      type="primary"
      size="medium"
      class="mt30"
      style="width: 86px"
    >
      保存
    </el-button>
  </div>
</template>

<script>
export default {
  name: 'moneySet',
  data() {
    return {
      complete: false,

      isOver: undefined,

      post: {
        reward_price_one: '',
        reward_price_two: '',
        reward_price_three: '',
        reward_price_four: '',
        reward_price_five: '',
        reward_price_six: '',
      },
    }
  },
  created() {
    this.getRewardSetting()
  },
  methods: {
    // 获取初始化数据
    getRewardSetting() {
      this.$http({
        url: '/giftOrder/rewardSetting',
        callback: ({ data }) => {
          this.post = data
          this.complete = true
        },
      })
    },

    //  保存数据
    setRewardSetting() {
      if (
        this.post.reward_price_one <= 0 ||
        this.post.reward_price_one > 9999 ||
        this.post.reward_price_two <= 0 ||
        this.post.reward_price_two > 9999 ||
        this.post.reward_price_three <= 0 ||
        this.post.reward_price_three > 9999 ||
        this.post.reward_price_four <= 0 ||
        this.post.reward_price_four > 9999 ||
        this.post.reward_price_five <= 0 ||
        this.post.reward_price_five > 9999 ||
        this.post.reward_price_six <= 0 ||
        this.post.reward_price_six > 9999
      ) {
        this.$root.prompt('请输入0.01～9999之间的数字！')
        return
      } else if (
        !this.post.reward_price_one ||
        !this.post.reward_price_two ||
        !this.post.reward_price_three ||
        !this.post.reward_price_four ||
        !this.post.reward_price_five ||
        !this.post.reward_price_six
      ) {
        this.$root.prompt('金额不可为0和留空！')
        return
      }
      this.$http({
        url: '/giftOrder/rewardSetting',
        data: this.post,
        callback: () => {
          this.$root.prompt({
            msg: '操作成功',
            type: 'success',
          })
        },
      })
    },

    // 展示增加按钮的交互
    moseover(val) {
      this.isOver = val
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .el-input-number.is-controls-right .el-input__inner {
  padding: 0;
}
::v-deep .el-input-number__increase {
  background-color: #fff;
  border: 0 !important;
}
::v-deep .el-input-number__decrease {
  background-color: #fff;
  border-left: 0 !important;
}
::v-deep .el-input-number {
  width: 131px;
}

.ml50 {
  margin-left: 50px;
}
#moneySet {
  .menberlist {
    display: flex;
    align-items: center;
    .money {
      font-size: 14px;
      color: #333333;
      margin-left: 10px;
    }
  }
}
</style>