<template>
  <div id="giftSet">
    <div class="heard">
      <el-button type="primary" style="width: 134px" @click="add">
        添加礼物 {{ customGift }}/8
      </el-button>
      <span class="tip">提示：最多可自定义添加8个礼物</span>
    </div>

    <pagination2
      url="/giftOrder/giftList"
      ref="childDialogBox"
      @complete="childDialogBoxComplete"
    >
      <template v-slot:default="{ tableData }">
        <el-table
          ref="dialogTable"
          :header-cell-style="{
            background: 'rgba(245,245,245,1)',
            color: '#333333',
          }"
          :data="tableData"
          class="dialogTable"
          style="width: 100%"
        >
          <el-table-column label="礼物图标">
            <template slot-scope="{ row }">
              <div class="picture">
                <img :src="row.icon" alt="" />
              </div>
            </template>
          </el-table-column>

          <el-table-column label="礼物名称" prop="name"></el-table-column>

          <el-table-column label="礼物单价(元)" prop="price"></el-table-column>

          <el-table-column label="类型" prop="is_default">
            <template slot-scope="{ row }">
              {{ row.is_default | getIsDefault }}
            </template>
          </el-table-column>

          <el-table-column label="操作" align="right" min-width="100px">
            <template slot-scope="{ row }">
              <el-button
                type="text"
                v-if="row.is_default == 2"
                @click="editGift(row)"
              >
                编辑
              </el-button>
              <span class="sp_drive" v-if="row.is_default == 2">|</span>
              <el-button
                type="text"
                v-if="row.is_show == 1"
                @click="giftChange(2, row.gift_id)"
              >
                隐藏
              </el-button>
              <el-button
                type="text"
                v-if="row.is_show == 2"
                @click="giftChange(1, row.gift_id)"
              >
                显示
              </el-button>
              <span class="sp_drive" v-if="row.is_default == 2">|</span>
              <el-button
                type="text"
                v-if="row.is_default == 2"
                @click="giftChange(3, row.gift_id)"
              >
                删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </template>
    </pagination2>

    <add-gift
      @colse="colse"
      :isedit="isedit"
      :pictrue="pictrue"
      v-if="status"
      @selctPicture="selctPicture"
      :dialogstatus.sync="status"
      :editform="form"
    ></add-gift>

    <!-- 本地上传弹窗 -->
    <upload
      :uploadType="2"
      v-if="uploadStart"
      @uploadConfirmClose="uploadConfirmClose"
      @close="uploadConfirmClose"
      :typeselection="1"
      title="上传图片(多选)"
      :list="group_id1"
    ></upload>

    <!-- 自定义添加图片 -->
    <addpicture
      v-if="dialogstatus"
      @group_id="group_id"
      @locationUpload="locationUpload"
      pictureTitle="添加图片(单选)"
      :type="1"
      :size="2"
      :dialogstatus.sync="dialogstatus"
      ref="addpicture"
      @list="list"
      @close="dialogstatus = false"
    ></addpicture>
  </div>
</template>

<script>
//本地上传弹窗
import upload from '@cm/base/UploadtextFile'

import addpicture from '@cm/base/addpicture'

import addGift from './addGift'

export default {
  name: 'giftSet',
  data() {
    return {
      group_id1: '',

      // 是否是编辑
      isedit: false,

      form: {},

      customGift: '',

      // 选择的图片数据
      pictrue: {},

      uploadStart: false,

      dialogstatus: false,

      status: false,
    }
  },
  components: { upload, addGift, addpicture },

  filters: {
    getIsDefault(val) {
      let text = ''
      switch (Number(val)) {
        case 1:
          text = '系统默认'
          break
        default:
          text = '自定义'
          break
      }
      return text
    },
  },

  methods: {
    editGift(val) {
      this.form = val
      this.isedit = true
      this.status = true
    },

    add() {
      if (this.customGift >= 8) {
        this.$root.prompt('数量已达上限！')
        return
      } else {
        this.status = true
      }
    },

    childDialogBoxComplete(val) {
      const { data } = val
      this.customGift = data.filter((item) => item.is_default == 2).length
    },

    uploadConfirmClose() {
      this.$refs.addpicture.renew()
      this.uploadStart = false
    },

    group_id(val) {
      this.group_id1 = val
    },

    locationUpload() {
      this.uploadStart = true
    },

    // 选择图片的回调
    list(val) {
      this.pictrue = val
    },

    selctPicture() {
      this.dialogstatus = true
    },

    colse() {
      this.pictrue = {}
      this.isedit = false
      this.form = {}
      this.$refs.childDialogBox.reset()
    },

    giftChange(val, val2) {
      if (val == 3) {
        this.$confirm('是否删除该礼物?', '温馨提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        })
          .then(() => {
            this.giftApi(val, val2)
          })
          .catch(() => {})
      } else {
        this.giftApi(val, val2)
      }
    },

    giftApi(val, val2) {
      this.$http({
        url: '/giftOrder/giftChange',
        data: {
          type: val,
          gift_id: val2,
        },
        callback: () => {
          this.$refs.childDialogBox.reset()
        },
        error: () => {
          this.$root.prompt('操作失败')
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.sp_drive {
  color: #1b9d97;
  margin: 0 10px;
}
#giftSet {
  .heard {
    margin-bottom: 20px;
    .tip {
      font-size: 12px;
      color: #333333;
      margin-left: 20px;
    }
  }
  .dialogTable {
    .picture {
      width: 40px;
      height: 40px;
      img {
        height: 100%;
        width: 100%;
        object-fit: contain;
      }
    }
  }
}
</style>