<template>
  <div id="rewardSetting">
    <el-radio-group v-model="radio1">
      <el-radio-button label="1">金钱</el-radio-button>
      <el-radio-button label="2">礼物</el-radio-button>
    </el-radio-group>
    <div class="tip">
      提示：设置的金额和礼物会作用公开课全部直播间，如需关闭打赏，可在对应的公开课单独设置，金额上限为9999元
    </div>
    <div class="price" v-if="radio1 == '1'">
      <money-set></money-set>
    </div>
    <div v-else>
      <gift-set></gift-set>
    </div>
  </div>
</template>

<script>
import moneySet from './rewardSettingComponents/moneySet'
import giftSet from './rewardSettingComponents/giftSet'

export default {
  name: 'rewardSetting',

  components: {
    moneySet,
    giftSet,
  },

  data() {
    return {
      radio1: '1',
    }
  },

  created() {},

  methods: {},
}
</script>

<style lang="scss" scoped>
::v-deep .el-radio-button__inner {
  width: 131px;
}
#rewardSetting {
  .tip {
    font-size: 12px;
    color: #333333;
    margin: 20px 0 30px;
  }
}
</style>