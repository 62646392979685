import { render, staticRenderFns } from "./addGift.vue?vue&type=template&id=8cbf61f2&scoped=true&"
import script from "./addGift.vue?vue&type=script&lang=js&"
export * from "./addGift.vue?vue&type=script&lang=js&"
import style0 from "./addGift.vue?vue&type=style&index=0&id=8cbf61f2&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8cbf61f2",
  null
  
)

export default component.exports